import * as React from "react";
import { Col, Row } from "reactstrap";
import ContentSection from "../../components/contentSection";
import Layout from "../../components/layout";
import PageTitle from "../../components/pageTitle";
import SEO from "../../components/seo";
import headerEducation from "../../images/header-education.jpg";
import educationERate from "../../images/education-e-rate.png";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

class EducationServices extends React.Component {
    render() {
        return (
            <Layout>
                <SEO title="Education Services" />

                <PageTitle
                    imageUrl={headerEducation}
                    title="K-12 Education Offering"
                />

                <ContentSection>
                    <Row className="mb-4">
                        <Col lg={6} xl={8}>
                            <h2>
                                Technology is transforming all aspects of
                                education
                            </h2>

                            <p>
                                Today’s students—and many of today’s
                                teachers—are thoroughly comfortable in the new,
                                media-augmented, real-time world of information.
                                To keep education relevant, today’s schools must
                                take advantage of the rich online resources that
                                motivate, inform and entertain these new
                                learners. Tiber Creek Consulting specializes in
                                custom-designed, quality information technology
                                products and services that help you deliver
                                academic instruction in ways that engage,
                                motivate and educate students. Our Education
                                Division provides professional technical
                                services in selected competencies to the K-12
                                community. Our mission is to be the premier
                                provider of technical services for North
                                Carolina and South Carolina K-12 institutions in
                                the area of Directory Services, Messaging
                                Services, and Desktop and Enterprise Management
                                Services.
                            </p>
                        </Col>

                        <Col lg={6} xl={4}>
                            <h3>Make Tiber Creek your E-Rate Partner</h3>

                            <img
                                src={educationERate}
                                alt="Students working at computers in a library"
                                className="img-fluid"
                            />
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col lg={6} xl={8}>
                            <div className="mb-4">
                                <h3>District-Wide Information Technology</h3>

                                <p>
                                    Tiber Creek’s Education Services Division
                                    has provided an array of network, server,
                                    storage, messaging, and security services to
                                    K-12 clients. We offer deep technical
                                    expertise combined with a wide product
                                    portfolio to meet our clients’ needs and
                                    budgets.
                                </p>
                            </div>

                            <h3>Maximize Classroom Technology</h3>

                            <p>
                                Classroom information technology holds great
                                promise for helping educators improve student
                                outcomes and reduce operational costs. At the
                                same time, local, state, and federal rules are
                                compelling educators to gather, analyze, store,
                                and report more education about students,
                                community demographics, and educational
                                outcomes.
                            </p>
                        </Col>

                        <Col lg={6} xl={4}>
                            <h3>Some of our K-12 clients:</h3>

                            <ul>
                                <li>Transylvania County Schools</li>
                                <li>Macon County Schools</li>
                                <li>Swain County Schools</li>
                                <li>Gaston County Schools</li>
                                <li>Yancey County Schools</li>
                                <li>Henderson County Schools</li>
                            </ul>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6}>
                            <h3>K-12 Solutions from Industry Leaders</h3>

                            <p>
                                Let Tiber Creek Consulting help you lead your
                                schools’ transformation into a 21st century,
                                technology-enhanced educational center of
                                excellence. We collaborate with partners
                                including Dell/EMC, Microsoft, and others to
                                help districts create digital learning
                                environments that enhance teaching and learning.
                            </p>
                        </Col>

                        <Col lg={6}>
                            <h3>Complement Your In-House Resources</h3>

                            <p>
                                At Tiber Creek, we understand the many demands
                                and constraints placed upon information
                                technology organizations operating within
                                educational institutions. We stand behind every
                                solution we deliver, with training and services
                                that ensure your schools receive the full value
                                of every dollar invested in educational
                                technology.
                            </p>
                        </Col>
                    </Row>
                </ContentSection>

                <ContentSection backgroundColor="dark" textColor="white">
                    <p>
                        Contact Tiber Creek Consulting for a complimentary
                        assessment of your existing infrastructure, and discover
                        how we can help deploy solutions, including Dell/EMC
                        virtualization and Microsoft Active Directory
                        technologies to support your educational goals. Learn
                        how you can save time, expenses and resources on your IT
                        operations, so you can refocus them on what you do the
                        best— helping today’s children become tomorrow’s
                        knowledge leaders. Contact our Education Services
                        Division today to learn more about how working with
                        Tiber Creek can help you achieve your school’s IT goals.
                    </p>
                </ContentSection>

                <ContentSection>
                    <div className="text-center">
                        <Link
                            to="/contact"
                            className="btn btn-lg btn-outline-dark"
                        >
                            Learn More with a Free Consultation
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                className="ml-3"
                            />
                        </Link>
                    </div>
                </ContentSection>
            </Layout>
        );
    }
}

export default EducationServices;
